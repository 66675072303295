import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeepLink } from '../types/deeplink.types';
import { User } from '../types/user.types';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getAllUsers() {
    return this.http.get<User[]>(environment.apiUrl + '/users/');
  }

  public deepLinkRegister(deepLink: DeepLink, login: string, password: string) {
    return this.http.post<HttpResponse<any>>(
      environment.apiUrl + '/users/dl_register/',
      {
        deeplink: deepLink.id,
        login,
        password,
      },
      { observe: 'response' }
    );
  }

  public deleteAccount(user) {
    return this.http.patch<HttpResponse<any>>(environment.apiUrl + '/users/delete_account/', { user });
  }
}
